import React from 'react'
import { Container, Flex, Section } from '../../../components/global'
import styled from 'styled-components'
import { Mail } from 'react-feather'
import { employee } from '../../const'
const EmployeePage = ({ employeeData }) => {
	const { name, surname, title, content, image, mail } = employeeData
	return (
		<Section id="page">
			<Container>
				<Title>{`${name} ${surname}`}</Title>
				<Subtitle> {title}</Subtitle>
				<Wrapper>
					<Picture>
						<img src={`/team_personal_page/${image}.png`} />
					</Picture>

					<Text>
						<p>
							<strong>{content[0]}</strong>
						</p>
						<PictureSmall>
							<img src={`/team_personal_page/${image}.png`} />
						</PictureSmall>
						{content.length > 1 &&
							content.map((item, index) => {
								if (index === 0) return
								return <p>{item}</p>
							})}

						{mail && (
							<ContactInfo>
								<a href={`mailto:${mail}`}>
									{
										<div>
											<Mail size={15} /> {mail}
										</div>
									}
								</a>
							</ContactInfo>
						)}
					</Text>
				</Wrapper>
			</Container>
		</Section>
	)
}

export default EmployeePage

const Title = styled.h4`
	margin: 0;
	margin-top: 75px;
`
const Subtitle = styled.h5`
	text-align: center;
	margin-bottom: 75px;
	text-transform: uppercase;
	font-size: 14px;

	/* background-color: #14293e; */

	/* margin-top: 75px; */
`
const Wrapper = styled(Flex)`
	align-items: flex-start;
	@media (max-width: ${(props) => props.theme.screen.md}) {
		flex-direction: column;
	}
`
const PictureSmall = styled.div`
	display: none;
	/* display: flex; */
	justify-content: center;
	img {
		width: 50%;
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		display: flex;
	}
`
const Picture = styled.div`
	margin-top: 20px;
	width: 30%;
	height: 400px;
	img {
		background: linear-gradient(157.99deg, #dddddd5c 8.66%, rgba(217, 217, 217, 0) 85.61%);
		border: 1px solid #f0f0f0;
		max-width: 80%;
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		width: 50%;
		order: 2;
		display: none;
	}
`
const Text = styled.div`
	width: 60%;

	p {
		/* text-indent: 2rem; */
		padding: 0 20px;
		strong {
			font-weight: ${(props) => props.theme.font.weight.bold};
		}
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		width: 100%;
		p {
			padding: 0;
		}
	}
`

const ContactInfo = styled.div`
	width: 100%;
	text-align: right;
	margin-top: 90px;
	font-size: ${(props) => props.theme.font.size.xxsmall};
	color: ${(props) => props.theme.color.secondary};
	/* margin: 15px auto; */
	svg {
		margin-right: 5px;
		margin-bottom: -3px;
	}
	> a {
		font-weight: ${(props) => props.theme.font.weight.medium};
		line-height: 1.5;
		display: inline-block;
		margin-left: 30px;
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		text-align: center;
	}
`
const Article = styled.article`
	padding: 30px 0 100px;
	strong {
		font-weight: ${(props) => props.theme.font.weight.regular};
	}
`
