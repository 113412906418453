import React from 'react'
import SEO from '../../components/common/seo.js'
import Layout from '../../components/common/layout.js'
import Navigation from '../../components/navigation/navigation.js'
import Header from '../../components/sections/header.js'
import EmployeePage from '../../components/sections/team/EmployeePage.js'
import { employee } from '../../components/const.js'
const userId = 27

const employeeData = employee[userId - 1]
const { name, surname, title, content, image, mail } = employeeData
const Employee = () => (
	<Layout>
		<SEO title={`${name} ${surname} - ${title}`} description={content[0]} />
		<Navigation />
		<Header />
		<EmployeePage employeeData={employeeData} />
	</Layout>
)

export default Employee
